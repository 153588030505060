<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        证书配置
      </template>
      <template #input>
        <a-input placeholder="请输入模板名称" v-model="certifName" allowClear />
        <a-select
          placeholder="请选择关联模板"
          v-model="pictureId"
          showSearch
          allowClear
        >
        
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option  v-for="item in pictureDropDownList" :key="item.pictureId" :value="item.pictureId"> {{item.pictureName}} </a-select-option>
        </a-select>
        <a-select
          placeholder="请选择关联课程"
          v-model="courseId"
          showSearch
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option  v-for="item in courseList" :key="item.courseId" :value="item.courseId"> {{item.courseName}} </a-select-option>
        </a-select>
        <a-button type="primary" class="btn" @click="onSearch()" v-hasPermi="['certif:config:list']">搜索</a-button>
        <a-button style="margin-left:24px" class="all_boder_btn" v-hasPermi="['certif:config:add']" @click="goDetail('',1)">新增证书配置</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item,index) => index"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :locale="{ emptyText: '暂无数据' }"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNum,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <!-- 序号 -->
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNum - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <!-- 证书模板图片 -->
        <template slot="generateType" slot-scope="item">
          <span>{{item == 1 ? '系统发放' : item == 2 ? '手动发放' : item == 3 ? '系统发放同时支持人工补录' : '-'}}</span>
        </template>
        <!-- 操作 -->
        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <a @click="deleteData(item)" v-hasPermi="['certif:config:delete']">删除</a>
            <a @click="goDetail(item.tempId,2)" v-hasPermi="['certif:config:update']"> | 编辑</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import HeaderBox from '@/components/HeaderBox.vue'
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "证书名称",
    align: "center",
    dataIndex: "certifName"
  },
  {
    title: "关联模板",
    align: "center",
    dataIndex: "pictureName",
  },
  {
    title: "关联课程",
    align: "center",
    dataIndex: "courseName",
  },
  {
    title: "发放方式",
    align: "center",
    dataIndex: "generateType",
    scopedSlots: { customRender: "generateType" },
  },
  {
    title: "证书编码预览",
    align: "center",
    dataIndex: "codePreview",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: {HeaderBox},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      loading:false,
      certifName:'', //证书名称
      courseId: undefined, // 关联课程
      pictureId: undefined, // 关联模板
      columns,
      total:1,
      pageNum: 1,
      pageSize: 10,
      tableData:[],
      courseList:[], // 课程列表
      pictureDropDownList:[], // 证书模板列表
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNum = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },
    // 搜索
    onSearch() {
      this.pageNum = 1;
      this.getData();
    },
    // 跳转详情页
    goDetail(id,type) {
      this.$router.push({
        path: "/admin/BasicConfig/CertificateConfigDetail",
        query: { tempId: id, operation: type },
      });
    },
    // 获取课程列表
    getCourseList() {
      this.$ajax({
        url: "/hxclass-management/course/select",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.courseList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取证书列表
    getPictureDropDownList() {
      this.$ajax({
        url: "/hxclass-management/certiftemp/getPictureDropDownList",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.pictureDropDownList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    
    // 获取数据
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/certiftemp/getTempConfigList",
        method: "get",
        params: {
          certifName: this.certifName,
          courseId: this.courseId,
          pictureId: this.pictureId,
          pageSize: this.pageSize,
          pageNum: this.pageNum,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    // 删除模板
    deleteData(item) {
      let _this = this;
      this.$confirm({
        title: "确认删除该证书配置?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/certiftemp/deleteTempConfig/" + item.tempId,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.visible = false;
                _this.getData();
              } else {
                _this.$message.error(res.message);
              }
            });
        },
        onCancel() {},
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getData()
    this.getCourseList()
    this.getPictureDropDownList()
   },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.pictrue{
  max-width: 100px;
}
</style>
